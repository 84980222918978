import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Product from '../Product';
import ProductType from '../ProductType';
import ProductProcesType from '../ProductProcesType';
import CuttingTypes from '../CuttingTypes';
import InstalationProduct from '../InstalationProduct';
import AdditionalProduct from '../AdditionalProduct';

const WrapperForm = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield !important;
  }
`;

const sizes = [
  {
    title: 'Lungimea pietrei',
    placeholder: '150',
    name: 'length',
    id: 'label_size_0',
    quantity: 'mm',
  },
  {
    title: 'Lățimea pietrei',
    placeholder: '350',
    name: 'width',
    id: 'label_size_1',
    quantity: 'mm',
  },
  {
    title: 'Bucăți de piese',
    placeholder: '1',
    name: 'nr_pieces',
    id: 'label_size_2',
    quantity: 'buc.',
  }
];


const FormBlock = ({ setAvaible, setSink, index, backend, setBackend, setUserData }) => {
  const handleChange = e => {
    const value = e.target.value;
    backend[index] = { ...backend[index], [e.target.name]: value };
    setBackend([...backend]);
  }
  const backIndex = backend && backend[index] && backend[index];
  return (
    <WrapperForm>
      <div className="atelier--bottom__block-1">
        <div className="atelier--bottom__type">
          {backIndex && (
            <ProductType
              setSink={setSink}
              setAvaible={setAvaible}
              index={index}
              setBackend={setBackend}
              backend={backend}
              setUserData={setUserData}
            />
          )}
        </div>
        <div className="atelier--bottom__type">
          {backIndex && backIndex.product_type && (
            <Product form={backend} setForm={setBackend} index={index} />
          )}
        </div>
      </div>
      <div className="atelier--bottom__block-2">  
        {backIndex && backIndex.product_id && sizes.map(size => {
          return (
            <div className="atelier--bottom__type-input" key={size.id}>
              <label htmlFor={size.id}>{size.title}</label>
              <input
                onChange={e => handleChange(e)}
                htmlFor={size.id}
                name={size.name}
                type='number'
                placeholder={size.placeholder}
              />
              <span>
                {size.quantity}
              </span>
            </div>
          );
        })}
        <div className="atelier--bottom__type">
          {backIndex && backIndex.length && backIndex && backIndex.width && backIndex && backIndex.nr_pieces && backend && backend[index].product_type !== 8 && (
            <ProductProcesType form={backend} setForm={setBackend} index={index} />
          )}
        </div>
      </div>

      <div className="atelier--bottom__block-4">
        {(backIndex && backIndex.product_type === 8 && backIndex.nr_pieces) || (backIndex && backIndex.processing_type) ? (
          <AdditionalProduct setSink={setSink} id={backIndex && backIndex.product_type} form={backend} setForm={setBackend} index={index} />
        ): null}
      </div>

      <div className="atelier--bottom__block-3">
        <div className="atelier--bottom__type-checkbox">
          {(backIndex && backIndex.product_type === 8 && backIndex.nr_pieces) || (backIndex && backIndex.processing_type) ? 
            (<CuttingTypes form={backend} setForm={setBackend} setAvaible={setAvaible} index={index} />) : null}
        </div>
        <div className="atelier--bottom__type-checkbox">
          {(backIndex && backIndex.product_type === 8 && backIndex.nr_pieces && backIndex.suplimentare.sink_instalation) || 
            (backIndex && backIndex.suplimentare && backIndex.suplimentare.sink_instalation) ? (
            <InstalationProduct setSink={setSink} form={backend} setForm={setBackend} index={index} />
          ) : null}
        </div>
      </div>
    </WrapperForm>
  )
}

export default FormBlock;
