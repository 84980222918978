import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import axios from 'axios';

const Image = styled.img`
  margin-right: 15px;
  max-height: 100px;
  max-width: 100%;
  height: auto;
  @media (max-width: 468px) {
    max-height: 50px;
  }
`;

const CustomBlock = styled.div`
  margin-left: auto;
`;

const Block = styled.div`
  display: flex;
  align-items: center;
`;

const customStyles = {
  container: (provided) => ({
    ...provided,
    minHeight: '43px',
    borderColor: '#000',
    borderRadius: 0,
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '43px',
    borderColor: '#000',
    borderRadius: 0,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '20px',
    '@media (max-width: 468px)': {
      fontSize: '14px',
    }
  }),
}

const Product = ({ form, setForm, index }) => {
  const [data, setData] = useState({ products: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
 
  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios('/products_list');
        setData(result.data);
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    };
 
    fetchData();
  }, []);

  const { products } = data;
  const handleChange = e => {
    form[index] = { ...form[index], product_id: e.value }
    setForm([...form]);
    return e.value;
  }
  const options = products.map(el => (
    {
      key: el._id,
      value: el._id,
      label: el.title,
      image: el.image,
      price: el.price,
    }
  ));
  const CustomOption = ({ children, ...props }) => {
    const options = props.options.filter(el => el.label === children);
    return (
      <components.Option {...props}>
        <Block>
          <div><Image src={`https://atelier.piatrashop.md/image/100x100/${options[0].image}`} /></div>
          <div>{children}</div>
          <CustomBlock>{options[0].price} mdl/m.p.</CustomBlock>
        </Block>
      </components.Option>
    );
  };
  return (
    <>
      <label>Tipul pietrei:</label>
      <Select
        styles={customStyles}
        components={{ Option: CustomOption }}
        name='product_id'
        placeholder='Selecteaza piesa'
        options={options || []}
        closeMenuOnSelect
        onChange={e => handleChange(e)}
      />
    </>
  )
};

export default Product;