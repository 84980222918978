import React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

const Button = styled.button`
  display: inline-block;
  border: 1px solid #3366cc;
  border-radius: 8px;
  min-width: 235px;
  color: #3366cc;
  font-family: 'OpenSans';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding: 10px 60px;
  &.blue {
    background-color: #3366cc;
    color: #fff;
  }
  &.white {
    margin-right: 20px;
    @media (max-width: 768px) {
      .white {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 768px) {
    min-width: 180px;
  }
`;

const Wrapper = styled.form`
  .form-buttons {
    display: flex;
    justify-content: space-between;
  }
`;

const Input = styled.input`
  border: 1px solid #000;
  margin-bottom: 20px;
  height: 50px;
`;

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 15%;
`;

const SimpleDiv = styled.div`
  color: #333333;
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 80px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const Form = styled.div`
  max-width: 490px;
  margin: 0 auto;
  margin-bottom: 50px;
`;

const popUpStyle = {
  borderRadius: '10px',
  padding: '80px 38px 56px 38px',
  width: '100%',
  maxWidth: '600px',
  textAlign: 'center',
};

const Modal = ({ trigger, actionToDo, setName, setPhone, setEmail, schema, success, setSuccessName }) => {
  return (
    <Popup trigger={trigger} contentStyle={popUpStyle} modal>
      {close => (
        <Wrapper className='modal'>
          <SimpleDiv className='content'>Comanda proiectul:</SimpleDiv>
          {success && (<h2 style={{color: '#B8912A', textAlign: 'center', marginBottom: '20px', fontSize: '20px'}}>{success}</h2>)}
          <Form>
            <div className="form-group">
              <label htmlFor="name11">Nume<span style={{ color: 'red', marginLeft: '5px' }}>*</span></label>
              <input type="name" onChange={e => setName(e.target.value)} className="form-control" id="name11" placeholder="Nume" />
            </div>
            <div className="form-group">
              <label htmlFor="phone11">Telefon<span style={{ color: 'red', marginLeft: '5px' }}>*</span></label>
              <input type="phone" onChange={e => setPhone(e.target.value)} className="form-control" id="phone11" aria-describedby="emailHelp" placeholder="Telefon" />
            </div>
            <div className="form-group">
              <label htmlFor="Email">Email</label>
              <input type="email" onChange={e => setEmail(e.target.value)} className="form-control" id="Email" placeholder="Email" />
            </div>
          </Form>
          <div className='form-buttons'>
            <Button
              className='white'
              type='button'
              onClick={() => {
                close();
              }}
            >
              Anuleaza
            </Button>
            <Button
              className='blue'
              type='button'
              onClick={(e) => {
                actionToDo(e, close);
              }}
            >
              Trimite
            </Button>
          </div>
        </Wrapper>
      )}
    </Popup>
  );
};

export default Modal;
