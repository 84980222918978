import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';

const CuttingTypes = ({ form, setForm, setAvaible, index }) => {
  const [data, setData] = useState({ cutting_types: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
 
  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios('/cutting_types');
        setData(result.data);
      } catch (error) {
        setIsError(true);
      } 
      setIsLoading(false);
    };
 
    fetchData();
  }, []);
  const { cutting_types } = data;
  const handleChange = e => {
    const value = e.target.value;
    form[index] = { ...form[index], cutting: value, [e.target.name]: value };
    setForm([...form]);
    setAvaible(true);
  }
  return (
    <>
    <p>Tipul taierii:</p>
      <div>
        {cutting_types && cutting_types.map(el => (
          <span key={el.id}>
            <input onChange={e => handleChange(e)} type="radio" id={el.name + '-' + index} name='cutting_type' value={el.id} />
            <label htmlFor={el.name + '-' + index}>
              {el.title.ro} {el.price} mdl/ml
            </label>
          </span>
        ))}
      </div>
    </>
  )
};

export default CuttingTypes;