import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Atelier from './Atelier.js';

const ElementBackground = styled.div`
  background-image: url('static/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
`;

const Wrapper = styled.div`
  display: flex;
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px 0;
`; 

const TextElements = styled.div`
  @media (max-width: 1024px) {
    padding: 0 30px;
  }
`;

const TextDescription = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #2D2D2D;
  letter-spacing: 0;
  line-height: 24px;
`;

const Heading = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 32px;
  color: #2D2D2D;
  letter-spacing: 0;
  margin-bottom: 25px;
`;

const Header = styled.div`
  display: flex;
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px 0;
  .header__top__contacts {
    &__phone {
      span {
        color: #b8912a;
      }
    }
    &__lucru {
      margin-top: 10px;
    }
    a {
      color: #000;
    }
  }
  .logo-content {
    display: flex;
    justify-content: space-between;
  }
  & .image-wrapper {
    max-height: 70px;
    img {
      max-width: 200px;
      position: relative;
      top: -45px;
      @media (max-width: 768px) {
        top: -20px;
        height: 120px;
      }
    }
  }
`;

const StoneValues = () => {
  return (
    <>
      <Header>
        <div className="col-md-12 col-sm-12 col-xs-12 text-center logo-content">
          <a className='image-wrapper col-xs-12' href="https://piatrashop.md/">
            <img className="img-responsive" src="https://piatrashop.md/images/logo.svg" alt="main logo" />
          </a>
          <div className="header__top__contacts  class='col-xs-12'"> <div className="header__top__contacts__phone">  <a href="tel:76767663"> <i className="fas fa-phone"></i> <span>+373</span> 76767663 </a>  <div></div></div> <div className="header__top__contacts__lucru hidden-xs"> <span className="header__top__contacts__phone"> <span><i className="far fa-clock"></i> Lu - Vi </span>9:00 - 18:00 </span> <span className="header__top__contacts__phone"> <span><i className="far fa-clock"></i> Sâ </span>10:00 - 14:00 </span> </div> </div>
        </div>
      </Header>
      <ElementBackground>
        <Wrapper>
          <TextElements>
            <Heading>Atelier Online</Heading>
              <TextDescription>
                În compartimentul Atelier online primești o oferta în regim online cu privire la debitarea și prelucrarea la comandă a pervazelor, blaturilor, treptelor și alte piese din piatră naturală. Pentru a calcula costul proiectului tău, indică dimensiunile aproximative sau concrete și alege tipul piesei solicitate, piatra potrivită proiectului și serviciile complementare privind executarea lucrării. În final algoritmul de calcul a prețului îți va prezenta o ofertă gratuită în baza indicilor completați.
              </TextDescription>
          </TextElements>
        </Wrapper>
      </ElementBackground>
      <Wrapper>
        <Atelier />
      </Wrapper>
    </>
  );
};

export default StoneValues;
