import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

const Button = styled.button`
  display: inline-block;
  border: 1px solid #3366cc;
  border-radius: 8px;
  min-width: 235px;
  color: #3366cc;
  font-family: 'OpenSans';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding: 10px 60px;
  margin-top: 40px;
  @media (max-width: 768px) {
    margin-right: 0 !important;
    min-width: 180px;
    .white {
      margin-right: 0;
    }
    &.white {
      margin-right: 0;
    }
  }
  &.white {
    margin-right: 20px;
  }
  &.blue {
    background-color: #3366cc;
    color: #fff;
  }
`;

const SimpleDiv = styled.div`
  color: #333333;
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 80px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const Form = styled.form`
  max-width: 490px;
  margin: 0 auto;
  margin-bottom: 50px;
`;

const StyledPopup = styled(Popup)`
  &-content {
    border-radius: 10px,
    padding: 80px 38px 56px 38px,
    width: 100%,
    max-width: 600px,
    text-align: center,
    @media (max-width: 768px) {
      padding: 15px 10px 10px 10px !important;
    }
  }
  &-overlay {
    width: 100% !important;
  }
`;

const popUpStyle = {
  borderRadius: '10px',
  padding: '50px 35px 50px 35px',
  width: '100%',
  maxWidth: '600px',
  textAlign: 'center',
};

const Modal = ({
    trigger,
    setSuccessName,
    success,
    setBackend,
    setAvaible,
    setUserData,
    setSink,
  }) => {
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [fileName, setFileName] = useState();

  const sendFormSchema = (e, close) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name)
    formData.append('phone_number', phone)
    formData.append('email', email)
    formData.append('image', e.target.form && e.target.form[3] && e.target.form[3].files && e.target.form[3].files[0])
    if (name && phone && e.target.form && e.target.form[3] && e.target.form[3].files && e.target.form[3].files[0]) {
      e.target.form.reset();
      fetch('/send_order', {
        method: 'POST',
        body: formData,
      })
      .then(res => res.json())
      .then(
        (result) => {
          setSuccessName('Comanda a fost transmisă cu succes.');
          setTimeout(() => {
            setSuccessName();
            setBackend([{product_type: ''}])
            setAvaible(false);
            setUserData(false);
            setSink(true);
            setName();
            setPhone();
            setEmail();
            setError();
            close();
          }, 5000);
        },
        (error) => {
          console.log(error);
        }
      )
    } else {
      setError('Introduceti datele: Nume, Telephone si schita proiectului');
    }
  }
  const handleChangeFile = (e) => {
    setFileName(e.target.files && e.target.files[0] && e.target.files[0].name);
  }
  return (
    <StyledPopup contentStyle={popUpStyle} trigger={trigger} modal>
      {close => (
        <div className='modal'>
          <SimpleDiv className='content'>Comanda proiectul:</SimpleDiv>
          {success && (<h2 style={{color: '#B8912A', textAlign: 'center', marginBottom: '20px', fontSize: '20px'}}>{success}</h2>)}
          <span style={{ color: '#b8912a', marginBottom: '15px', display: 'block'}} className='error'>{error}</span>
          <Form onSubmit={e => sendFormSchema(e)}>
            <div className="form-group">
              <label htmlFor="name11">Nume<span style={{ color: 'red', marginLeft: '5px' }}>*</span></label>
              <input type="name" name="name" onChange={e => setName(e.target.value)} className="form-control" id="name11" placeholder="Name" />
            </div>
            <div className="form-group">
              <label htmlFor="phone11">Telefon<span style={{ color: 'red', marginLeft: '5px' }}>*</span></label>
              <input type="phone" name="phone" onChange={e => setPhone(e.target.value)} className="form-control" id="phone11" aria-describedby="emailHelp" placeholder="Phone" />
            </div>
            <div className="form-group">
              <label htmlFor="Email">Email</label>
              <input type="email" name="email" onChange={e => setEmail(e.target.value)} className="form-control" id="Email" placeholder="Email" />
            </div>
            <div className="form-group">
              <label htmlFor="exampleFormControlFile1">
                Încarcă schița proiectului<span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                <div
                  type='button'
                  className='button-upload'
                  style={{
                    margin: '0px auto',
                    marginTop: '0px',
                    marginTop: '10px',
                    border: '1px solid #bb9533',
                    borderRadius: '10px',
                    padding: '10px 10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '150px',
                    color: '#bb9533',
                    cursor: 'pointer',
                  }}
                >alege file</div>
                {fileName && <span style={{ marginTop: '5px', display: 'block' }}>File: {fileName}</span>}
              </label>
              <input
                style={{ display: 'none', margin: '0 auto', marginTop: '5px' }}
                name="image"
                accept="image/jpeg,image/png"
                type="file"
                onChange={e => handleChangeFile(e)}
                className="form-control-file"
                id="exampleFormControlFile1"
                data-buttontext="Alege file"
              />
            </div>
            <Button
              className='white'
              type='button'
              onClick={() => {
                close();
              }}
            >
              Anuleaza
            </Button>
            <Button
              className='blue'
              type='button'
              onClick={(e) => sendFormSchema(e, close)}
            >
              Trimite
            </Button>
          </Form>
        </div>
      )}
    </StyledPopup>
  );
};

export default Modal;
