import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';

const InstalationProduct = ({ form, setForm, setSink, index }) => {
  const [data, setData] = useState({ sink_instalation: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
 
  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios('/sink_instalation');
        setData(result.data);
      } catch (error) {
        setIsError(true);
      } 
      setIsLoading(false);
    };
 
    fetchData();
  }, []);
  const { sink_instalation } = data;
  const handleChange = el => {
    form[index] = { ...form[index], chiuvetii: el.target.value };
    setForm([...form]);
    setSink(true);
  }
  return (
    <>
    <p>Instalarea Chiuvetei</p>
    <div>
      {sink_instalation.map(el => (
        <span key={el.name}>
          <input onChange={el => handleChange(el)} type="radio" id={el.name + '-' + index} name={`sink_instalation-${index}`} value={el.id} />
          <label htmlFor={el.name + '-' + index}>
            {el.title.ro} {el.price} mdl
          </label>
        </span>
      ))}
    </div>
    </>
  )
};

export default InstalationProduct;