import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import axios from 'axios';

const Image = styled.img`
  margin-right: 15px;
  max-height: 100px;
  max-width: 100%;
  height: auto;
  @media (max-width: 468px) {
    max-height: 50px;
  }
`;

const CustomBlock = styled.div`
  margin-left: auto;
  > div {
    display: none;
  }
  @media (max-width: 468px) {
    > span {
      display: none;
    }
    > div {
      display: block;
    }
  }
`;

const Block = styled.div`
  display: flex;
  align-items: center;
`;

const customStyles = {
  container: (provided) => ({
    ...provided,
    minHeight: '43px',
    borderColor: '#000',
    borderRadius: 0,
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '43px',
    borderColor: '#000',
    borderRadius: 0,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '16px',
    '@media (max-width: 468px)': {
      fontSize: '14px',
    }
  }),
}

const ProductProcesType = ({ form, setForm, index }) => {
  const [data, setData] = useState({ processing_types: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
 
  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios('/processing_types');
        setData(result.data);
      } catch (error) {
        setIsError(true);
      } 
      setIsLoading(false);
    };
 
    fetchData();
  }, []);
  const { processing_types } = data;
  const handleChange = el => {
    form[index] = { ...form[index], processing_type: el.value, cutting: false };
    setForm([...form]);
  }
  const options = processing_types.map(el => (
    {
      key: el.id,
      value: el.id,
      label: el.title.ro,
      image: el.image || '',
      name: el.name,
      price: el.price
    }
  ));
  const CustomOption = ({ children, ...props }) => {
    const options = props.options.filter(el => el.label === children);
    return (
      <components.Option {...props}>
        <Block>
          <div><Image src={`https://atelier.piatrashop.md/static/images/${options[0].value}.png`} /></div>
          <div>{children}</div>
          <CustomBlock>{options[0].price} <span>lei/m.l.</span></CustomBlock>
        </Block>
      </components.Option>
    );
  };
  return (
    <>
      <label htmlFor={'label_processing_type' + '-' + index}>Tipul prelucrari laturii vizible:</label>
      <Select
        styles={customStyles}
        components={{ Option: CustomOption }}
        placeholder='Selecteaza tipul'
        name='label_processing_type'
        options={options || []}
        closeMenuOnSelect
        onChange={e => handleChange(e)}
      />
    </>
  )
};

export default ProductProcesType;
