import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  span {
    width: 100%;
    display: flex;
    align-content: center;
    label {
      margin-top: 2px;
    }
  }
  .additional {
    width: 100%;
    display: flex;
  }
`;

const AdditionalProduct = ({ id, form, setForm, setSink, index }) => {
  const [data, setData] = useState({ additional_services: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
 
  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios(`/additional_services?product_type_id=${id}`);
        setData(result.data);
        // if (result.data.additional_services.some(item => item.name === 'synk_instalation')) {
        //   setSink(false);
        // } else {
        //   setSink(true);
        // }
      } catch (error) {
        setIsError(true);
      } 
      setIsLoading(false);
    };
 
    fetchData();
  }, []);
  const { additional_services } = data;
  const handleChange = e => {
    const value = e.target.value;
    form[index] = { ...form[index], cutting: true, suplimentare: {
      ...form[index].suplimentare,
      [value]: e.target.checked,
    }};
    setForm([...form]);
    if (e.target.name === 'sink_instalation' && e.target.checked) {
      setSink(false);
      console.log('disalow chiuvetii')
    }
    if (e.target.name === 'sink_instalation' && !e.target.checked) {
      setSink(true);
      form[index].chiuvetii = false;
    }
  }
  return (
    additional_services && additional_services.length !== 0 && (
      <Wrapper>
        <div className="atelier--bottom__type-checkbox">
          <p>Servicii suplimentare</p>
          <div className='additional'>
            {additional_services.map((el, i) => (
              <span key={el.id}>
                <input onChange={e => handleChange(e)} type="checkbox" id={el.name + '-' + index} name={el.name} value={el.name} />
                <label htmlFor={el.name + '-' + index}>
                  {el.title.ro} {el.price} mdl
                </label>
              </span>
            ))}
          </div>
        </div>
      </Wrapper>
    )
  )
};

export default AdditionalProduct;