import React, { useState } from 'react';
import styled from 'styled-components';

import FormBlock from './Form';
import Modal from './Modal';
import ModalSchema from './ModalSchema';

const Wrapper = styled.div`
  width: 100%;
  .atelier {
    margin-top: 35px;
    width: 100%;
  }

  .atelier--top {
    position: relative;
  }

  .atelier--top:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    content: '';
  }

  .atelier--top > div {
    padding: 30px 0;
  }

  .atelier--top__title {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 32px;
    color: #2D2D2D;
    letter-spacing: 0;
    margin-bottom: 25px;
  }

  .atelier--top__description {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #2D2D2D;
    letter-spacing: 0;
    line-height: 24px;
  }

  .atelier--bottom__title {
    margin: 60px 0 50px 0;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: #222222;
    letter-spacing: 0;
    text-align: center;
  }

  .atelier--bottom__block-1 {
    display: flex;
    @media (max-width: 1024px) {
      width: 100%;
      flex-direction: column;
      > div {
        width: 100%;
      }
    }
  }

  .atelier--bottom__block-2 {
    display: flex;
    margin-top: 45px;
    @media (max-width: 1024px) {
      width: 100%;
      flex-direction: column;
      > div {
        width: 100%;
        margin-top: 15px;
      }
    }
  }

  .atelier--bottom__block-3 {
    display: flex;
    margin-top: 30px;
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      width: 100%;
      flex-direction: column;
      > div {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  .atelier--bottom__block-4 {
    display: flex;
    padding-right: 30px;
    @media (max-width: 1024px) {
      width: 100%;
      flex-direction: column;
    }
  }

  .atelier--bottom__type {
    font-family: 'Montserrat', sans-serif;
    color: #222222;
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0 15px;
    @media (max-width: 1024px) {
      width: 100%;
      margin-top: 20px;
    }
  }

  .atelier--bottom__type-input {
    font-family: 'Montserrat', sans-serif;
    color: #222222;
    display: flex;
    flex-direction: column;
    width: 16.66%;
    padding: 0 15px;
    position: relative;
    @media (max-width: 1024px) {
      width: 33.33%;
    }
  }

  .atelier--bottom__type label {
    padding: 0 0 5px 0;
  }

  .atelier--bottom__type-input label {
    padding: 0 0 5px 0;
  }

  .atelier--bottom__type-input span {
    position: absolute;
    bottom: 10px;
    right: 25px;
    color: #B8912A;
    font-weight: bold;
  }

  .atelier--bottom__type-input span:before {
    content: '';
    position: absolute;
    height: 41px;
    top: -12px;
    right: 38px;
    width: 1px;
    background-color: gray;
  }

  .atelier--bottom__type select {
    border: 1px solid gray;
    padding: 10px 5px;
    background: url('./arrow.svg');
    background-position: 98%;
    background-size: 30px;
    background-repeat: no-repeat;
    /* reset */
    border-radius: 0;
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance:none;
    -moz-appearance:none;
  }

  .atelier--bottom__type-checkbox {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0 15px;
  }

  .atelier--bottom__type-checkbox > div {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .atelier--bottom__type-checkbox > div > span {
    width: 50%;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }

  .atelier--bottom__type-checkbox > div > span label {
    padding-left: 12px;
    font-weight: 300;
  }

  .atelier--bottom__type-checkbox p {
    border-bottom: 1px solid #909090;
    font-weight: bold;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }

  .atelier--bottom__block-4 .atelier--bottom__type-checkbox {
    width: 100%;
    padding: 0;
    margin: 0 15px;
    margin-top: 30px;
  }

  .atelier--bottom__type-input input {
    border: 1px solid gray;
    padding: 10px 47px 10px 10px;
    min-height: 42px;
    /* reset */
    border-radius: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance:none;
    -moz-appearance:none;
  }

  .atelier__buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 60px;
    padding: 0 15px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      div, button {
        text-align: center;
      }
      button {
        margin-top: 10px;
      }
    }
  }

  .atelier__buttons button {
    border: 1px solid #B8912A;
    color: #B8912A;
    padding: 5px 15px;
    width: 220px;
    height: 59px;
    background-color: transparent;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    @media (max-width: 768px) {
      padding: 5px 10px;
    }
  }

  .atelier__calc {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    margin-bottom: 150px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    p {
      font-size: 1.5em;
      display: flex;
      align-items: center;
      font-weight: 300;
      margin: 0;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .atelier__calc button {
    color: #fff;
    border: none;
    padding: 5px 15px;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    background-color: #B8912A;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    @media (max-width: 768px) {
      padding: 5px 10px;
    }
  }

  .atelier__buttons .modal {
    border: none;
    color: #000;
    padding: 0;
    background-color: #fff;
    > div {
      border: none;
      color: #000;
    }
    div {
      border: none;
      color: #000;
      padding: 0;
    }
  }

  .atelier__buttons > div {
    border: 1px solid #B8912A;
    color: #B8912A;
    padding: 5px 15px;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    text-transform: uppercase;
    font-family: 'Montserrat',sans-serif;
    @media (max-width: 768px) {
      padding: 5px 10px;
    }
  }

  button:disabled {
    opacity: 0.2;
    border: 1px solid #333;
    pointer-events: none;
    color: #333;
    background-color: #fff;
  }
  .button-add {
    cursor: pointer;
  }
  .button-add.disabled {
    opacity: 0.2;
    border: 1px solid #333;
    color: #333;
    background-color: #fff;
    pointer-events: none;
  }
  .wrapper-form {
    > div {
      margin-bottom: 150px;
      opacity: 0.5;
      pointer-events: none;
      :last-child {
        opacity: 1;
        pointer-events: all;
        margin-bottom: 0;
      }
    }
  }
`;

const Atelier = () => {
  const [avaible, setAvaible] = useState(false);
  const [userData, setUserData] = useState(false);
  const [backend, setBackend] = useState([{product_type: ''}]);
  const [sink, setSink] = useState(true);
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [image, setImage] = useState();
  const [successName, setSuccessName] = useState();
  const avaibleButton = sink && avaible;
  const addForm = () => {
    setBackend([...backend, {}]);
    setAvaible(false);
    setSink(true);
    setUserData(false);
  }
  
  const handleSubmit = event => {
    event.preventDefault();
    const dataForBackend = backend.map(el => {
      // console.log(el);
      return (
        {
          sink_instalation: parseInt(el.chiuvetii, 10),
          cutting_type: el.cutting,
          gas_cooker_hole: el.gas_cooker_hole,
          instalation: el.instalation,
          length: el.length,
          nr_pieces: el.nr_pieces,
          processing_type: el.processing_type,
          product_id: el.product_id,
          product_type: el.product_type,
          washbasin_hole: el.washbasin_hole,
          width: el.width,
          additional_services: el.suplimentare,
        }
      )
    })
    fetch('/check_price', {
      method: 'POST',
      body: JSON.stringify(dataForBackend),
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result);
        if (result.success) {
          setUserData(result.price_data);
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }
  const sendForm = (e, close) => {
    e.target.form.reset();
    const dataForBackend = backend.map(el => {
      return (
        {
          sink_instalation: parseInt(el.chiuvetii, 10),
          cutting_type: el.cutting,
          gas_cooker_hole: el.gas_cooker_hole,
          instalation: el.instalation,
          length: el.length,
          nr_pieces: el.nr_pieces,
          processing_type: el.processing_type,
          product_id: el.product_id,
          product_type: el.product_type,
          washbasin_hole: el.washbasin_hole,
          width: el.width,
          additional_services: el.suplimentare,
        }
      )
    })
    fetch('/send_order', {
      method: 'POST',
      body: JSON.stringify({
        name: name,
        phone_number: phone,
        email: email,
        price_data: dataForBackend
      }),
    })
    .then(res => res.json())
    .then(
      (result) => {
        setSuccessName('Comanda a fost transmisă cu succes.');
        setTimeout(() => {
          setSuccessName();
          setBackend([{product_type: ''}])
          setAvaible(false);
          setUserData(false);
          setBackend([{product_type: ''}]);
          setSink(true);
          setName();
          setPhone();
          setEmail();
          close();
        }, 5000);
      },
      (error) => {
        console.log(error);
      }
    )
  }
  const buttonTrigger = (
    <button type='button' disabled={!userData}>
      Comandă
    </button>
  );
  return (
    <Wrapper onSubmit={e => handleSubmit(e)} className="atelier">
      <div className="atelier--bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="atelier--bottom__title">
                Calculator
              </div>
              <div className='wrapper-form'>
                {backend && backend.map((el, index) => {
                  return (
                    <FormBlock 
                      setSink={setSink}
                      setAvaible={setAvaible}
                      key={index}
                      index={index}
                      backend={backend}
                      setBackend={setBackend}
                      setUserData={setUserData}
                    />
                  )
                })}
              </div>

              <div className="atelier__buttons">
                <div className={`button-add ${!avaibleButton && 'disabled'}`} onClick={() => addForm()}>
                  Adaugă o altă piesă
                </div>
                <ModalSchema
                  actionToDo={() => null}
                  trigger={<button type='button'>Încarcă schița proiectului</button>}
                  setSuccessName={setSuccessName}
                  success={successName}
                  setBackend={setBackend}
                  setAvaible={setAvaible}
                  setUserData={setUserData}
                  setSink={setSink}
                  setName={setName}
                  setPhone={setPhone}
                  setEmail={setEmail}
                  setImage={setImage} 
                />
              </div>
              <div className="atelier__calc">
                <button onClick={(e) => handleSubmit(e)} disabled={!avaibleButton} type='submit'>
                  Calculează
                </button>

                {userData && (
                  <p>Pret: {userData} mdl</p>
                )}
                {userData && (
                  <Modal
                    actionToDo={(e, close) => sendForm(e, close)}
                    trigger={buttonTrigger}
                    setName={setName}
                    setPhone={setPhone}
                    setEmail={setEmail}
                    price={userData}
                    success={successName}
                    setSuccessName={setSuccessName}
                  />
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Atelier;