import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

const customStyles = {
  container: (provided) => ({
    ...provided,
    minHeight: '43px',
    borderColor: '#000',
    borderRadius: 0,
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '43px',
    borderColor: '#000',
    borderRadius: 0,
  }),
}

const ProductType = ({ setSink, setAvaible, setBackend, backend, index, setUserData }) => {
  const [data, setData] = useState({ product_types: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
 
  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios('/products_types');
        setData(result.data);
      } catch (error) {
        setIsError(true);
      } 
      setIsLoading(false);
    };
 
    fetchData();
  }, []);
  const { product_types } = data;

  const handleChange = el => {
    setUserData(false);
    backend[index] = { product_type: el.value, sink_instalation: false, suplimentare: {} };
    setBackend([...backend]);
    setSink(true);
    setAvaible(false);
  };
  const options = product_types.map(el => (
    {
      key: el.id,
      value: el.id,
      label: el.title.ro,
      image: el.image || '',
      name: el.name,
    }
  ));
  return (
    <>
      <label htmlFor={'label_product_type' + index}>Tipul piesei speciale:</label>
      <Select
        styles={customStyles}
        placeholder='Selecteaza piesa'
        name='product_type'
        options={options || []}
        closeMenuOnSelect
        onChange={e => handleChange(e)}
      />
    </>
  )
};

export default ProductType;